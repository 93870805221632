<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-04-02 20:46:48
 * @LastEditors: guozong
 * @LastEditTime: 2022-08-29 13:04:59
-->
<template>
  <div class="top">
    <div class="content-header">
      <!-- <img @click="$router.push({path:'/home'})" src="~@/assets/back.png" class="img" /> -->

      <p class="title-img">
        <span>51公需网数据驾驶舱</span>
      </p>

      <span class="time">{{`${dateYear} ${dateWeek}`}} {{dateDay}}</span>
    </div>
    <!-- <div class="navbar">
      <div class="left">

      </div>
      <div class="center">
        <ul class="routers">
          <li :class="{active:item.path === $route.path}" @click="handleGoTo(item)" v-for="(item,index) in routerList"
            :key="index">
            {{item.title}}
          </li>
        </ul>
      </div>
      <div class="right">

      </div>
    </div> -->
  </div>
</template>

<script>
  import {
    formatTime
  } from "@/utils/index.js";

  export default {
    mounted() {
      this.timeFn();
    },
    beforeDestroy() {
      clearInterval(this.timing);
    },
    methods: {
      handleGoTo({
        path
      }) {

        if (this.$route.path === path) return;

        this.$router.push({
          path
        })
      },
      timeFn() {
        this.timing = setInterval(() => {
          this.dateDay = formatTime(new Date(), "HH: mm: ss");
          this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
          this.dateWeek = this.weekday[new Date().getDay()];
        }, 1000);
      },
    },
    data() {
      return {
        timing: null,
        dateDay: null,
        dateYear: null,
        dateWeek: null,
        weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
        routerList: [{
          title: '数据驾驶舱一',
          path: '/optimizedDecision/optimizedDecision_1',
        }, {
          title: '数据驾驶舱二',
          path: '/optimizedDecision/optimizedDecision_2',
        }]
      }
    }
  };
</script>

<style lang="scss" scoped>
  .top {
    position: absolute;
    z-index: 10;
    top: 0;
    width: 100%;

    .content-header {
      // background-color: #000;
      background-image: url("~@/assets/optimizedDecision/icon-2-17.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      height: 90px;
      width: 100%;
      position: relative;

      .title-img {
        position: absolute;
        top: 5px;
        height: 70px;
        line-height: 70px;
        text-align: center;
        left: 0;
        right: 0;
        margin: 0 auto;

        span {
          background: linear-gradient(to bottom, #ffffff, #42c2f4);
          -webkit-background-clip: text;
          color: transparent;
          text-align: center;
          font-size: 58px;
          font-weight: 900;
        }
      }

      .img {
        cursor: pointer;
        top: 40px;
        left: 50px;
        position: absolute;
        font-size: 20px;
        width: 150px
      }

      .time {
        top: 50px;
        right: 50px;
        position: absolute;
        font-size: 20px;
      }
    }

    .navbar {
      height: 70px;
      display: flex;
      justify-content: center;

      .center {
        background-image: url("~@/assets/optimizedDecision/header.png");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        height: 100%;
        width: 80%;
        margin-top: 5px;

        .routers {
          height: 100%;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 900;

          li {
            user-select: none;
            width: 300px;
            font-size: 24px;
            color: #ACD9FF;
            transition: all 0.3s;

            &.active,
            &:hover {
              color: white;
              cursor: pointer;
              text-shadow: 0px 3px 7px rgba(255, 255, 255, 0.6);

              .icon {
                display: none;
              }

              .icon-active {
                display: inline-block;
              }

            }
          }
        }
      }
    }
  }
</style>