<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-07-01 22:06:36
 * @LastEditors: guozong
 * @LastEditTime: 2022-08-18 08:52:02
-->
<template>
    <div id="index" ref="appRef">
        <div class="dark" />

        <div class="bg">
            <iframe v-if="env !== 'development'" id="iframe" :src="iframeUrl" width="1920px" height="1080px"
                frameborder="0" />
            <dv-loading v-if="loading">51公需网数据驾驶舱</dv-loading>
            <Header />
            <transition name="confirm-fade">
                <router-view></router-view>
            </transition>
        </div>
    </div>
</template>

<script>
    import drawMixin from "@/utils/drawMixin";

    import Header from "./components/Header/index.vue";

    export default {
        data() {
            return {
                loading: true,
                env: process.env.NODE_ENV,
                iframeUrl: process.env.VUE_APP_IFRAMEURL
            }
        },
        mixins: [drawMixin],
        components: {
            Header
        },
        mounted() {
            // this.timeFn();
            this.cancelLoading();
        },
        beforeDestroy() {
            clearInterval(this.timing);
        },
        methods: {
            cancelLoading() {
                setTimeout(() => {
                    this.loading = false;
                }, 500);
            },
        },
    };
</script>

<style lang="scss">
    #iframe {
        z-index: 0;
        position: absolute;
    }

    .confirm-fade-enter-active {
        animation: confirm-fadein .3s;

        .confirm-content {
            // animation: confirm-zoom-in .3s;
        }
    }

    .confirm-fade-leave-active {
        animation: confirm-fadeout .3s;

        .confirm-content {
            animation: confirm-zoom-out .3s;
        }
    }

    @keyframes confirm-fadein {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes confirm-fadeout {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes confirm-zoom-in {
        0% {
            transform: scale(0);
        }

        50% {
            transform: scale(1.1);
        }

        100% {
            transform: scale(1);
        }
    }

    @keyframes confirm-zoom-out {
        0% {
            transform: scale(1);
        }

        100% {
            transform: scale(0);
        }
    }
</style>